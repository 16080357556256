.notes {
  border: 1px solid #4d4d4d;
  border-radius: 4px;
  padding: 16px 24px;
  margin-top: 19px;

  .notes-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    .notes-date {
      padding-right: 12px;
      border-right: 1.4px solid #666666;
    }

    .notes-time {
      padding-left: 12px;
      padding-right: 12px;
      border-right: 1.4px solid #666666;
    }

    .notes-user {
      padding-left: 12px;
    }
  }

  .notes-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 15px;
    white-space: pre-line;
  }
}

.active {
  border: 1px solid #6D1DF0;
}

.small {
  // height: 100px;
  padding: 12px;
  cursor: pointer;

  .notes-header {
    font-size: 12px;

    .notes-time {
      border-right: none;
    }
  }

  .notes-content {
    height: 48px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.large {
  height: 100%;
  box-sizing: border-box;
  border: none;
  padding: 24px;
  margin-top: 0px;
  overflow: scroll;

  .notes-header {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }

  .notes-content {
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #999999;
    margin-top: 27px;
  }
}