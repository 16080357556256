.drug-administration-container {
    table {
        width: 100%;
        height: 40px;
        border-collapse: collapse;
        text-align: center;
        overflow: scroll;

        td,
        th {
            border: 1px solid #4D4D4D;
        }

        tr {
            border-bottom: 0.5px solid grey;
        }

        .sticky {
            position: sticky;
            left: 0;
        }

        th {
            border-bottom: 1px solid black;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            background: #333333;
            padding: 16px;
        }

        td {
            text-align: center;
            background: #1f1f1f;
            padding: 12px 16px;

            .drug-detail {
                text-align: left;
                min-width: 150px;

                .drug-name {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #FFFFFF;
                    margin-bottom: 10px;
                }

                .drug-data {
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    justify-content: left;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    color: #FFFFFF;

                    .drug-box {
                        padding: 4px 6px;
                        background: #333333;
                        border-radius: 4px;

                    }
                }

                .drug-user {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    color: #808080;
                    margin-top: 8px;
                }

            }

            .drug-type {
                text-align: left;
            }

            .drug-remark {
                min-width: 200px;
                text-align: left;
            }

            .drug-checkbox {
                // width: 100%;
                // height: 80%;
                height: 15px;
                width: 20px;
                border: none;
                background: green;
                color: white;
                padding: 0px;
                box-sizing: border-box;
            }

            .drug-checkbox:checked {
                accent-color: green;
            }

            .drug-checkbox[aria-disabled="true"] {
                pointer-events: none;
                accent-color: darkolivegreen !important;
            }

            .administration {
                .user {
                    margin-top: 14px;
                    position: relative;

                    .user-id-hover {
                        position: absolute;
                        width: 153px;
                        height: 58.17px;
                        background: #4D4D4D;
                        left: 0%;
                        padding: 12px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        text-align: left;

                        .id {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                            color: #FFFFFF;
                        }

                        .user-name {
                            margin-top: 5px;
                        }
                    }
                }

                .user-id-hover {
                    display: none;
                }

                .user:hover .user-id-hover {
                    display: block;
                }
            }
        }
    }


}