.patient-right-panel-container {
    // overflow: scroll;
    height: 100%;

    .bed-info-container {
        background-color: #141414;
        display: flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
        position: sticky;
        top: 0px;
        z-index: 20;


        .bed-header {
            display: flex;
            justify-content: space-between;
            padding: 5px 15px;

            height: 48px;

            .cross-container {
                width: 20px;
                height: 20px;
                cursor: pointer;
                position: relative;
                right: 8px;
                top: 10px;
                // right: 0px;
                // top: 3px;
            }

            .bed-number {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                letter-spacing: -0.02em;
            }
        }

        .patient-details {
            display: flex;
            gap: 8px;
            color: #9A9A9A;
            padding: 0px 15px;
        }

        .sub-header-switch {
            display: flex;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            gap: 40px;
            cursor: pointer;
            padding: 20px 10px 0px 10px;
            position: sticky;
            top: 5px;
            background-color: black;
            position: relative;

            .common-header {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.02em;
                color: #4D4D4D;
            }

            .active-header {
                padding-bottom: 5px;
                border-bottom: 2px solid #ffffff;
                color: #FFFFFF;
            }
        }

    }

    .active-subheader-body-container {
        margin-top: 40px;
        height: 90%;
    }

}