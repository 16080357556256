.header {
  // background-color: red;
  // height: 9.7%;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: #0d0d0d;
  z-index: 1000;
  // box-shadow: 1px 1px; //TODO: REMOVE IT

  .logo-container {
    padding-left: 20px;
    display: flex;
    align-items: center;
    height: 100%;

    .dashboard-text {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      margin-left: 28px;
    }
  }

  .ward-container {
    display: flex;
    align-items: center;
    padding-right: 36px;

    .hospital-name-container {
      // width: 150px;
      border-right: 1px solid #808080;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      padding-right: 10px;


      // margin-right: 35px;
    }

    .volume-icon-container {
      margin-right: 19px;
    }
  }
}