.bed-container {
    // height: 80%;
    min-height: 600px;
    display: flex;
    padding-left: 8px;
    height: 100%;

    .bed-grid-data-container {
        margin-top: 12px;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px 10px;

        // transition: 2s;
        > .bed-card {
            //   border: solid 1px red;
            height: 22%;
            min-height: 180px;
        }

    }

    .left-panel {
        width: 20%;
        // margin-top: 25px;
        padding-bottom: 20px;
        gap: 10px;
        flex-direction: column;
        overflow: scroll;
        justify-content: space-around;
    }

    .right-panel {
        width: 79.2%;
        padding: 0px 24px 0px 24px;
        // overflow: scroll;
    }

    .bed-list-data-container {
        margin-top: 26px;
        padding-bottom: 10px;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: 50% 50%;
        // grid-template-columns: 33% 66%;
        // grid-gap: 23px 18px;
        height: 100%;
        // overflow: scroll;

        // .bed-list-card {
        //   height: 100%;
        //   overflow: scroll;
        //   display: grid;
        //   grid-gap: 23px;
        //   grid-row: 1/3;

        //   .bed-card {
        //     //   border: solid 1px red;
        //     // height: 22%;
        //     min-height: 160px;
        //     grid-column: 1/2;
        //   }
        // }

        // .bed-list-data {
        //   grid-column: 2/4;
        //   grid-row: 1/3;
        //   // background-color: red;
        //   height: 100%;
        //   // overflow: scroll;
        // }
    }
}

@media screen and (min-device-width: 1600px) {
    .bed-container {

        .bed-grid-data-container {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media screen and (max-device-width: 1500px) {
    .bed-container {

        .left-panel {
            width: 27%;
        }

        .right-panel {
            width: 69.2%;
            padding: 0px 24px 0px 24px;
            overflow: scroll;
        }
    }
}