.bed-details {
    height: 100%;
    width: 100%;
    overflow: scroll;

    .bed-details-body-container {
        padding: 16px 36px 0px 16px;
        // background-color: red;
        max-height: calc(100% - 80px);
        max-width: calc(100% - 80px);
        height: 90.3%;
        width: 94.5%;
        float: right;
        box-sizing: border-box;
        margin-top: 80px;

        .bed-header {
            display: flex;
            justify-content: space-between;

            .bed-id {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
            }

            .cross-img-container {
                cursor: pointer;
            }
        }

        .module-heading-container {
            margin-top: 17px;
            display: flex;
            gap: 24px;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            overflow: scroll;

            .module-heading {
                padding-bottom: 10px;
                cursor: pointer;
            }

            .selected {
                border-bottom: 2px solid #FFFFFF;
            }
        }

        .module-container {
            // margin-top: 36px;
            margin-top: 24px;
            height: 80%;
        }
    }
}