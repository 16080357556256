.table {
    overflow: scroll;
    display: flex;
    height: 100% ;
    width: 100%;
    .heading {
        background: #141414;
        text-align: center;
        position: sticky;
        top: 0;

    }
    .column {
        flex: auto;
    }
    .parameters {
        position: sticky;
        left: 0px;
        background: black;
        max-width: 180px;
        z-index: 1000;
    }

    .param {
        overflow: scroll;
    }
    .cell {
        padding: 12px 16px;
        border: 1px solid #333333;
        height: 48px;
        box-sizing: border-box;
        text-align: center;
    }
}
