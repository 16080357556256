.side-bar {
  background-color: #0d0d0d;
  height: 90.3%;
  margin-top: 80px;
  max-height: calc(100% - 80px);
  width: 5.5%;
  min-width: 80px;
  z-index: 1000;
  position: fixed;

  .sidebar-icon-container {
    // padding-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    height: 100%;

    .top-icon-container {}

    .bottom-icon-container {
      cursor: pointer;
    }
  }

  .icon-container {
    margin-bottom: 48px;
  }
}