.backdrop-container{
    position:absolute;
    color: red;
    background-color: aqua;
    height: 100vh;
    width: 100vw;
    z-index: 1002;
    top: 0px;
    left: 0px;
    background: rgba(13, 13, 13, 0.1);
    backdrop-filter: blur(20px);
}