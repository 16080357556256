.home-page {
  height: 100%;
  width: 100%;
  overflow: scroll;
  background-color: #000000;

  .home-page-body-container {
    max-height: calc(100% - 80px);
    max-width: calc(100% - 80px);
    height: 90.3%;
    width: 94.5%;
    float: right;
    box-sizing: border-box;
    margin-top: 72px;
  }

  .toggle-filter-container {
    margin-top: 12px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);

    .toggle-switch-container {
      min-width: 225px;

      .grid-view-text {
        margin-right: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
      }

      .list-view-text {
        margin-left: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        vertical-align: middle;
      }
    }

    .filter-container {
      // grid-column: 6/6;
      // justify-self: end;
      grid-column: 2/2;
      justify-self: left;
      margin-left: 20px;
    }

    .add-column-container {
      grid-column: 6/6;
      justify-self: end;
      position: relative;

      .add-column-button {
        background: #6D1DF0;
        border-radius: 6px;
        padding: 13px 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 12px;
        text-align: center;
        cursor: pointer;
        min-width: 134px;
      }

      .add-column-option-container {
        position: absolute;
        z-index: 1000;
        top: 110%;
        background-color: #333333;
        width: 100%;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;

        .option-heading {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          padding: 5px 10px 5px 16px;
        }

        .add-column-option {
          display: flex;
          text-align: left;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          padding: 10px 10.5px 10px 16px;

          .option-label {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

.signout-modal-container {
  position: absolute;
  width: 428px;
  height: 268px;
  left: 80px;
  bottom: 0px;

  border-radius: 6px;
  z-index: 1002;

  .modal-content-container {
    display: flex;
    background: rgba(52, 52, 52, 0.5);
    border: 1px solid #404040;
    backdrop-filter: blur(40px);
    flex-direction: column;
    padding: 24px;

    .signout-text {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
    }

    .signout-confirm-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .signout-final-statement {
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      color: #CCCCCC;
      margin-bottom: 12px;
    }

    .signout-btn-container {
      display: flex;
      gap: 20px;

      .common-signout-btn {
        width: 172px;
        height: 48px;
        border-radius: 4px;
        border: 2px solid #6D1DF0;
        text-align: center;
        line-height: 1px;
        font-weight: 600;
        font-size: 16px;
        line-height: 44px;
        cursor: pointer;
        color: #6D1DF0;
      }

      .active-signout-btn {
        background: #6D1DF0;
        color: white;
      }

    }
  }
}