.table {
    overflow: scroll;
    display: flex;
    height: 100% ;
    width: 100%;

    .heading {
        background: #141414;
        text-align: center;
        position: sticky;
        top: 0;

    }

    .column {
        flex: auto;

        .not-allowed {
            cursor: not-allowed;
        }
    }

    .parameters {
        position: sticky;
        left: 0px;
        background: black;
        max-width: 180px;
        z-index: 1000;
    }

    .param {
        overflow: scroll;
    }

    .double-checkbox-container {
        display: flex;
        text-align: center;
        justify-content: space-between;
        align-items: center;

        .double-checkbox-item {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: end;
        }
    }
    .cell {
        padding: 12px 16px;
        border: 1px solid #333333;
        height: 48px;
        box-sizing: border-box;
        text-align: center;

        .chart-input {
            // -webkit-appearance: none;
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            color: white;
            padding: 0px;
            box-sizing: border-box;
            outline: none;
        }

        .chart-input[readonly] {
            cursor: not-allowed;
        }

        .chart-checkbox,
        .chart-radio {
            width: 100%;
            height: 80%;
            min-height: 15px;
            min-width: 15px;
            border: none;
            background: green;
            color: white;
            padding: 0px;
            box-sizing: border-box;
        }

        .chart-checkbox:checked {
            accent-color: green;
        }

        .chart-radio:checked {
            accent-color: green;
        }

        .chart-checkbox[aria-disabled="true"] {
            pointer-events: none;
            accent-color: darkolivegreen !important;
        }

        .chart-radio[aria-disabled="true"] {
            pointer-events: none;
            accent-color: darkolivegreen !important;
        }
    }
}
