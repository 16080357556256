.custom-progress-container {
  height: 7px;
  width: 100%;
  border: 1px solid #808080;

  .custom-progress {
    height: 100%;
    max-width: 100%;
    background-color: #ffffff;
    border-radius: inherit;
    text-align: right;
    transition: width 1s ease-in-out;
  }

  .custom-label-container {
    padding: 4px 2px;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
}
