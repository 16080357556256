.ventilator-data-container {

    .ventilator-vitals-container {

        display: grid;
        grid-template-columns: repeat(8, 1fr);
        align-items: center;
        justify-items: center;
        margin-top: 70px;
        gap: 50px;

        .vital-data {
            .main-name {
                font-weight: 400;
                font-size: 32px;
                line-height: 44px;
            }

            .param-value {

                .value-data {
                    font-weight: 600;
                    font-size: 60px;
                    line-height: 82px;
                    display: inline-block;
                }

                .value-unit {
                    margin-left: 8px;
                }
            }
        }

        .left-arrow-container {
            justify-self: flex-start;
            cursor: pointer;
        }

        .right-arrow-container {
            // text-align: end;
            justify-self: flex-end;
            cursor: pointer;
        }

        .disabled {
            cursor: not-allowed;
            // pointer-events: none;
        }
    }
}