.chart-container {
  height: 94%;
  width: 100%;
  position: relative;
  // min-height: 650px;

  .chart-header-container {
    display: flex;
    justify-content: space-between;
    // margin-top: 17px;

    .charting-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }

    .chart-download-date-container {
      display: flex;
      gap: 15px;

      .download-container {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .download-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #6D1DF0;
        }
      }

      .date-container {
        // .react-date-picker__inputGroup {
        //   display: none;
        // }
      }
    }

    // .start-charting-button {
    //   padding: 13px 12px;
    //   background: #6d1df0;
    //   border-radius: 6px;
    //   font-weight: 600;
    //   font-size: 16px;
    //   line-height: 22px;
    // }
  }
  .clinical-modal-container{
    position: absolute;
    bottom: 50px;
    z-index: 1004;
    right: 0px;
    width: 337px;
    height: 100px;
    background: #2d2d2d;
    border-radius: 8px;

    .clinical-modal-options{
        display: flex;
        flex-direction: column;
        // height: 10px;
        padding: 10px;

        .common-clinical-cls{
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            cursor: pointer;
            align-items: center;
            padding: 11px 10px 11px 16px;
            gap: 10px;
            border-radius: 6px;
        }
        .default-select{
            background: #6D1DF0;

        }
    }
  }

  .charting-option-download-container {
    display: flex;
    justify-content: space-between;
    margin-top: 38px;

    

    .charting-option-container {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      display: flex;
      gap: 24px;
      color: #808080;
      overflow: scroll;

      .charting-option {
        padding-bottom: 10px;
        cursor: pointer;
      }

      .selected-chart-option {
        border-bottom: 2px solid #ffffff;
        color: #FFFFFF;
      }
    }

    .chart-option-download-date-container {
      display: flex;
      gap: 15px;

      .download-container {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        .download-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #6D1DF0;
        }
      }
    }
  }


  .chart-data-container {
    margin-top: 24px;
    // height: 79%; 
    height: 86%;
    width: 100%;

    .chart-vital-container {
      height: 100%;
      width: 100%;

      .time-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        .start-time {
          display: flex;
          border-radius: 8px;
          justify-content: space-between;
          background: #333333;
          padding: 7.5px 16px;
          gap: 60px;
          align-items: center;

          .time-text {
            color: #999999;
          }

          .time-data {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            .interval-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
            }

            .interval-input {
              width: 100%;
              height: 100%;
              border: none;
              background: none;
              color: white;
              padding: 3px;
              box-sizing: border-box;
              -webkit-appearance: none;
            }

            .interval-input::-webkit-calendar-picker-indicator {
              opacity: 100;
            }
          }
        }

        .interval-time {
          // display: flex;
          border-radius: 8px;
          justify-content: space-between;
          background: #333333;
          padding: 7.5px 16px;
          // gap: 60px;
          align-items: center;

          .time-data {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            .interval-label {
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
            }

            .interval-input {
              width: 100%;
              height: 100%;
              border: none;
              background: none;
              color: white;
              padding: 3px;
              box-sizing: border-box;
              -webkit-appearance: none;
            }

            .interval-input::-webkit-calendar-picker-indicator {
              opacity: 100;
            }
          }
        }
      }

      .data-table-container {
        margin-top: 24px;
        height: 89%;
        overflow: scroll;

        @media screen and (min-width: 1025px) and (max-width: 1440px) {
          height: 80%;
        }
      }

      .footer-container {
        // margin-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        bottom: 0%;
        position: absolute;
        width: 100%;

       

        .timeline-container {
          display: flex;
          gap: 10px;

          .prev-timeline-link {
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            position: relative;


            .link {
              color: #6D1DF0;
              cursor: pointer;
            }

            .prev-timeline {
              position: absolute;
              bottom: 150%;
              width: 382px;
              height: 270px;
              padding: 16px;
              background: #333333;
              border-radius: 8px;
              z-index: 1001;

              .timeline-header {
                display: flex;
                justify-content: space-between;

                .time {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  color: #FFFFFF;
                }

                .cross {
                  cursor: pointer;
                }
              }

              .timeline-body {
                margin-top: 28px;
                display: flex;
                gap: 37px;
                flex-direction: column;

                .timeline {
                  display: flex;
                  gap: 40px;

                  .body {
                    display: flex;
                    gap: 7px;
                    flex-direction: column;

                    .user {
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 22px;
                    }

                    .chart-type {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 16px;
                      color: #999999;
                    }
                  }
                }
              }
            }
          }

          .last-timeline {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
          }
        }

        .button-container {
          width: 25%;
          float: right;
          display: flex;
          gap: 24px;
          min-width: 200px;
          justify-content: flex-end;
          
          
          

          .chart-button {
            padding: 6px 12px;
            border-radius: 6px;
            height: 48px;
            width: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-sizing: border-box;
          }

          .chart-cancel {
            border: 1px solid #808080;
            color: #808080;
          }

          .chart-submit {
            background: #6d1df0;
          }

          .chart-edit {
            background: #6d1df0;
          }
        }
      }
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .chart-data-container {
      height: 60%;
       
    }
  }
}