.ward-detail-container {
  background: #1a1a1a;
  border-radius: 8px;
  min-height: 93px;
  box-sizing: border-box;
  padding: 12px 0 10px 24px;
  display: flex;

  .ward-bed-details {
    .ward-name {
      color: #999999;
    }
    .bed-details {
      display: flex;
      margin-top: 11px;
      border-right: 1px solid #808080;
      margin-right: 34px;

      .bed-box {
        margin-right: 59px;
      }
      .bed-heading {
        font-weight: 400;
      }

      .bed-data {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .ward-ews-score {
    .ward-name {
      color: #999999;
    }
    .bed-details {
      display: flex;
      margin-top: 11px;

      .bed-box {
        margin-right: 59px;
      }
      .bed-heading {
        font-weight: 400;

        .ews-type {
          margin-left: 6px;
        }
      }

      .bed-data {
        text-align: center;
      }

      .bed-data {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
