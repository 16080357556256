.confirm-modal-container{
    float: right;
    width: 335px;
    height: 95px;
    right: 5%;
    bottom: 7%;
    background: #1F1F1F;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: absolute;
    gap: 20px;

    .confirm-message{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .confirm-btn-container{
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-end;


        .common-btn{
            padding: 6px 12px;
            gap: 10px;
            width: 65px;
            height: 22px;
            left: 294px;
            top: 84px;
            background: #1F1F1F;
            border: 1px solid #666666;
            border-radius: 4px;
            text-align: center;
            line-height: 1.6;
            cursor: pointer;
        }
        .default-btn{
            background: #6D1DF0;
        }
    }

}