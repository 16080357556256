.patient-detail-container {
    // width: 100%;
    height: 100%;
    padding: 20px 10px;

    .patient-details {
        width: 100%;
        min-width: 400px;
        margin-bottom: 15px;

        .required-field {
            // color: red;
            margin-left: 3px;
        }

        .pd-input-container {
            margin-bottom: 25px;

            .input-label {
                margin-bottom: 5px;
                color: #999999;
            }

            .pd-input {
                width: 100%;
                height: 100%;
                box-sizing: border-box;
                background: #141414;
                border-radius: 8px;
                padding: 12px 16px;
                border: none;
                color: #FFFFFF;
                font-size: 16px;
                outline: none;
            }

            .pd-input:read-only {
                cursor: not-allowed;
            }

            .gender-input-container {
                display: flex;
                text-align: center;
                align-items: baseline;

                .pd-input-radio {
                    margin-right: 8px;

                }

                .pd-input-radio:disabled {
                    margin-right: 8px;
                    cursor: not-allowed;
                }

                .pd-input-radio:checked {
                    font-weight: bold;
                    color: Green;
                }

                .male-label,
                .female-label,
                .other-label {
                    margin-right: 38px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }

        .patient-dob-height-weight-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
            box-sizing: border-box;

            .admin-date {
                .admission-calendar {
                    background: #141414;
                    height: 42px;
                    border-radius: 6px;
                    width: 100%;
                    font-size: 15px;
                }
            }

            .admission-time-container {
                .time-container {
                    height: 42px;

                    .admission-time-picker{
                        .react-time-picker__inputGroup__amPm option{
                            background-color: #000000;
                        }
                    }
                    .react-time-picker--disabled {
                        background-color: #141414;
                        border-radius: 8px;
                    }

                    .react-time-picker__wrapper {
                        border-radius: 8px;
                    }

                }
            }

            .react-date-picker__wrapper {
                border: none;
                padding-inline-start: 15px;
            }

            .react-date-picker__inputGroup {
                padding: auto 10px;
            }

            input,
            .react-date-picker__inputGroup__input {
                color: white;
            }

            .pd-input {
                height: unset;
            }

            .blood-group-select {
                height: 42px;
            }
        }

        .submit-container {
            // grid-column: 3/4;
            display: flex;
            gap: 16px;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;

            .submit-button {
                padding: 10px 20px;
                color: #FFFFFF;
                background: #6D1DF0;
                border-radius: 4px;
                cursor: pointer;
            }

            .discharge {
                background: #AD0606;
                ;
            }
        }
    }

    .discharge-modal-container {
        position: absolute;
        width: 525px;
        top: 35%;
        left: 30%;
        height: 237px;
        background: #1F1F1F;
        border-radius: 12px;
        padding: 36px;
        z-index: 1005;
        backdrop-filter: blur(40px);

        .discharge-content {
            display: flex;
            flex-direction: column;
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            gap: 16px;

            .discharge-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                text-align: left;

            }

            .discharge-btn-container {
                display: flex;
                gap: 10px;
                margin-top: 32px;
                width: 80%;
                align-self: center;
                justify-content: space-between;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;

                .modal-btn {
                    border-radius: 6px;
                    width: 163px;
                    height: 48px;
                    text-align: center;
                    line-height: 45px;
                    cursor: pointer;
                }

                .cancel {
                    background: #333333;
                    border-radius: 6px;
                }

                .discharge {
                    background: #AD0606;
                }


            }
        }
    }
}