.graph-data {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(4, 1fr);
  height: 100%;
  // background-color: red;
  padding: 7px 15px;

  .graph-container {
    grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column: 1/5;
    // background-color: green;
    // > div {
    //   height: 22%;
    // }
    .ecg-graph-1 {
      height: 100px;
    }

    .ecg-graph-2 {
      height: 90px;
    }

    .pleth-graph {
      height: 120px;
    }

    .resp-graph {
      height: 140px;
    }
    .etco2-graph{
      height: 150px;
    }
    .ibp1-graph{
      height: 140px;
    }
    .ibp2-graph{
      height: 140px;
    }
    .select-vital-ecg-container {
      display: flex;
      gap: 3px;
      position: relative;
      cursor: pointer;
      width: 50px;

      img {
        accent-color: #268D3D;
      }

      .selected-vital-ecg-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        width: 30px;
        color: #299842;
      }

      .vital-graph-options {
        position: absolute;
        top: 20px;
        width: 126px;
        // height: 372px;
        padding: 10px;
        background: rgba(52, 52, 52, 0.5);
        border: 1px solid #404040;
        backdrop-filter: blur(40px);
        border-radius: 6px;
        z-index: 10;

        .common-vital-ecg {
          padding: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 36px;
          &:hover{
            background-color: #9F33FF;
          }
        }


        .vital-ecg-active {
          background-color: #6D1DF0;
          border-radius: 4px;
        }
      }
    }
  }

  .data-container {
    display: grid;
    grid-column: 5/6;
    grid-template-rows: 1fr 0.8fr 1fr 1fr 1fr 1fr 1fr;
    // grid-gap: 5px;
    .vital-ecg-option {
      display: grid;
      margin-left: 20px;
      width: 100%;
      color: #47ff7b;
      grid-template-columns: 0.3fr 2fr 1.6fr;
      gap: 20px;

      .ecg-param-col {
        .ecg-entry {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          height: 30px;
          gap: 3px;

          .ecg-param-name {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            // width: 10px;
          }

          .ecg-param-val {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: -0.05em;
          }
        }
      }
    }

    .vital-data {
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column: 1/5;
      padding: 0px 15px;
      display: grid;
      text-align: center;
      justify-content: center;
      align-items: center;

      .vital-name-container {
        grid-column: 1/2;
        grid-row: 1/2;

        .vital-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .vital-value-container {
        grid-column: 2/6;
        grid-row: 1/4;
      }

      .vital-lb-container {
        grid-column: 1/2;
        grid-row: 3/4;
      }

      .vital-rt-container {
        grid-column: 6/8;
        grid-row: 1/2;
      }

      .vital-rb-container {
        grid-column: 6/8;
        grid-row: 3/4;
        .mean-ibp{
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          letter-spacing: -0.05em;
        }
      }

      .rt-vital-name,
      .rb-vital-value {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }

      .vital-value-container {
        font-weight: 500;
        font-size: 80px;
        line-height: 109px;
      }

      .source-ecg {
        font-weight: 400;
        font-size: 8px;
        line-height: 11px;
      }
    }

    .ecg {
      color: #47ff7b;
      // gap: 10px;
    }

    .spo2 {
      color: #47f4ff;
    }

    .resp {
      color: #ffed47;
    }
    .etco2{
      color: #6D1DF0;
    }

    .ibp1{
      color: #F01DCE;
      .ibp1-val-main{
        font-weight: 500;
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -0.05em;
      }
    }
    .ibp2{
      color: #F01DCE;
      .ibp2-val-main{
        font-weight: 500;
        font-size: 44px;
        line-height: 60px;
        letter-spacing: -0.05em;
      }
    }
  }

  .temp-container {
    grid-column: 1/3;
    grid-row: 4/5;
    // background-color: blue;
    display: grid;
    // grid-template-columns: repeat(4, 1fr);
    grid-template-columns: 0.5fr 1fr 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    // grid-template-rows: 0.5fr 1fr 1fr;
    // text-align: center;
    justify-content: center;
    align-items: center;
    // color: #999999;
    padding: 25px 15px 15px 15px;

    .temp-name-container {
      grid-column: 1/2;
      grid-row: 1/2;
      align-self: flex-start;

      .temp-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }

      .temp-value {
        font-weight: 500;
        font-size: 40px;
        line-height: 54px;
        letter-spacing: -0.05em;
      }
    }

    .temp-hl-container {
      grid-column: 1/2;
      grid-row: 2/3;
      // align-self: flex-end;

      .temp-high,
      .temp-low {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .temp-t1-container {
      grid-column: 2/3;
      grid-row: 1/3;
      align-self: center;
      text-align: center;
    }

    .temp-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }

    .temp-value {
      font-weight: 500;
      font-size: 40px;
      line-height: 54px;
    }

    .temp-t2-container {
      grid-column: 3/4;
      grid-row: 1/3;
      align-self: center;
      text-align: center;
    }

    .temp-td-container {
      grid-column: 4/5;
      grid-row: 1/3;
      text-align: center;

      .td-name {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      .td-value {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;
      }
    }
  }

  .bp-container {
    // grid-column: 4/6;
    // grid-row: 4/5;
    // // background-color: red;
    // display: grid;
    // // grid-template-rows: 0.4fr 0.2fr 0.5fr;
    // grid-template-rows: 0.5fr 0.5fr 1fr;
    // grid-template-columns: repeat(3, 1fr);
    // padding: 15px;

    grid-column: 4/6;
    grid-row: 4/5;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    padding: 25px 15px 15px 15px;

    .progress-bar-container {
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      grid-row: 2/3;
      text-align: center;

      .nibp-name-container {
        grid-column: 1/2;
        grid-row: 1/2;
        align-self: flex-start;

        .nibp-name {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }

        .nibp-unit {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;

        }
      }

      .progress-bar {
        grid-column: 2/12;
        padding: 2px 10px;
      }
    }

    .nibp-name-container {
      grid-column: 1/2;
      grid-row: 1/2;
      align-self: flex-start;

      .nibp-name {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }

      .nibp-value {
        font-weight: 500;
        font-size: 40px;
        line-height: 54px;
        letter-spacing: -0.05em;
      }
    }

    .nibp-hl-container {
      grid-column: 1/2;
      grid-row: 2/3;
      // align-self: flex-end;

      .nibp-high,
      .nibp-low {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .nibp-data-container {
      // display: grid;
      // grid-template-columns: repeat(5, 1fr);
      // align-content: center;
      // grid-row: 3/3;
      grid-column: 2/3;
      grid-row: 1/3;
      align-self: center;

      .nibp-data {
        text-align: center;
        font-weight: 500;
        font-size: 60px;
        line-height: 82px;
        letter-spacing: -0.05em;
        align-self: center;
      }
    }

    .nibp-avg-data {
      font-weight: 500;
      font-size: 40px;
      line-height: 54px;
      align-self: center;
      grid-column: 3/4;
      grid-row: 1/3;
      align-self: center;
      justify-self: flex-end;
    }


  }

  .progress-bar-container {
    .wrapper {
      // border: 1px solid #808080;
      height: 7px;
      // background-color: red;
    }

    .container {
      // background-color: green;
      height: 7px;
      border: 1px solid #808080;

      > div {
        background: white !important;
        height: 7px !important;
      }
    }

    .barCompleted {
      background-color: #ffffff;
      width: attr(completed);
      border: 1px solid #ffffff;
      height: 7px;
    }
  }
}