.add-prescription-component {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 20px;

    .dosage,
    .remarks,
    .prescribed-by {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: #333333;
        border-radius: 8px;
        border-style: none;
        padding: 16px;
        font-size: 16px;
        color: #999999;
        min-width: 142px;
    }

    .remarks-container {
        grid-column: 1/3;
    }

    .submit-container {
        grid-column: 3/4;
        display: flex;
        gap: 16px;
        justify-content: flex-end;

        .cancel-button {
            padding: 10px 20px;
            border: 1px solid #666666;
            border-radius: 4px;
            cursor: pointer;
        }

        .submit-button {
            padding: 10px 20px;
            color: #FFFFFF;
            background: #6D1DF0;
            border-radius: 4px;
            cursor: pointer;
        }
    }

    .prescription-start-time-container,
    .prescription-end-time-container {
        display: flex;
        flex-direction: row;
        min-width: 217px;
    }

    .prescription-start-time,
    .prescription-end-time {
        width: 100%;
        height: 100%;

        .react-datetime-picker__inputGroup__input {
            color: #999999;
            font-size: 15px;
            // width: 30px;
        }

        .react-datetime-picker__clear-button {
            color: #999999;
        }

        .react-datetime-picker__calendar-button {
            color: #999999;
        }
    }
}