.login-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-width: 500px;
    width: 100%;
//     width: 1440px;
// height: 1024px;


    .background-logo-container{
        // z-index: -1;
        // left: 3%;
        box-sizing: border-box;

        .login-circle-vector{
            position: absolute;
            height: 520px;
            width: 520px;
            z-index: 20;
            top: 2%;
            left: 42%;
            transform: matrix(0.07, -1, 0.99, 0.09, 0, 0);
        }

        .parabola-axis-login2-vector{
            position: absolute;
            height: 530px;
            width: 540px;
            z-index: 10;
            left: 62%;
            top: 34%;
        }
        .parabola-login-vector{
            position: absolute;
            height: 440px;
            width: 440px;
            // left: 3%;
            box-sizing: border-box;
            right: 2.1%;
            top: 44%;
        }
        .login2-line-vector{
            position: absolute;
            height: 100%;
            // width: 1000px;
            // top: 10;
            right: 6.1%;
            box-sizing: border-box;
        }
        .rec-1{
            position: absolute;
            height: 30px;
            width: 35px;
            right: 9.45%;
            top: 10%;
        }
        .rec-2{
            position: absolute;
            height: 30px;
            width: 35px;
            right:56.3%;
            top: 30%;
            transform: rotate(150deg);
            z-index: 21;

        }
        .cir-1{
            position: absolute;
            height: 14px;
            width: 14px;
            right:37.5%;
            top: 33.4%;
            z-index: 21;
        }
        .cir-2{
            position: absolute;
            height: 14px;
            width: 14px;
            right:22.6%;
            top: 22.4%;
            z-index: 21;
        }
        .cir-3{
            position: absolute;
            height: 14px;
            width: 14px;
            right:20.5%;
            top: 70.4%;
            z-index: 21;
        }
    }


    .left-panel {
        box-sizing: border-box;
        padding: 3.8% 5.4% 0 5%;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: scroll;

        .logo-container {
            font-weight: 600;
            line-height: 22px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 11px;
            font-size: 34px;
        }
        .logo-container img{
            width: 48px;
            height: 37.13px;
            margin-bottom: 20px;
        }

        .content-container {
            // padding: 7% 10% 0% 0%;
            // margin-top: max(20%, 90px);
            z-index: 20;
            position: absolute;
            width: 670px;
            height: 434px;
            left: 25%;
            top: 20%;
            box-sizing: border-box;
            // background: rgba(94, 94, 94, 0.7);
            border-radius: 24px;
            padding:48px;

            .sign-in-text-container {
                font-weight: 600;
                font-size: 28px;
                line-height: 38px;
                text-align: center;
                letter-spacing: -0.24px;
            }

            .login-form-container {
                margin-top: 5.3%;

                input:-webkit-autofill {
                    -webkit-box-shadow: 0 0 0 50px  #1F1F1F inset; /* Change the color to your own background color */
                    -webkit-text-fill-color: white;
                }

                .login-input {
                    width: 100%;
                    height: 54px;
                    box-sizing: border-box;
                    background: #1F1F1F;
                    border-radius: 8px;
                    border-style: none;
                    padding: 11px 16px;
                    font-size: 16px;
                    color: #FFFFFF;
                    min-width: 142px;
                    margin-bottom: 6px;
                    margin-top: 10px;
                }

                .login-label {
                    padding-left: 10px;
                }

                .sign-in-botton-container {
                    margin-top: 48px;

                    .sign-in-botton {
                        width: 100%;
                        height: 100%;
                        background: #6D1DF0;
                        border-radius: 6px;
                        border-style: none;
                        padding: 6px 12px;
                        color: #FFFFFF;
                        min-height: 40.5px;
                        cursor: pointer;
                    }

                    .disabled {
                        background: grey;
                        pointer-events: none;
                    }
                }

            }
        }

        .footer-container {
            min-width: 170px;
            // position: absolute;
            // bottom: 0;
            width: 31%;
            margin-top: min(13%, 70px);
            margin-left: -13.8%;

            .login-logo-vector {
                width: 100%;
            }
        }
    }

    // .right-panel {
    //     align-items: flex-end;
    //     background: #1a1a1a;
    //     box-sizing: border-box;
    //     display: flex;
    //     justify-content: center;
    //     padding: 5.4%;
    //     width: 50%;
        

        
    //     .content-container {
    //         height: 80%;
    //         width: 100%;

    //         .illustration-container {
    //             width: 100%;
    //             height: 65%;
    //             text-align: center;
    //             max-height: 70%;
    //             .illustration {
    //                 width: 75%;
    //                 max-width: 260px;
    //             }
    //         }
    //         .below-illustration-container{
    //             margin-top: 30px;
    //             .logo-container {
    //                 text-align: center;
    //             // margin-top: 40px;
    //             }

    //         .content {
    //             .heading {
    //                 font-weight: 600;
    //                 font-size: 24px;
    //                 line-height: 33px;
    //                 text-align: center;
    //                 margin-top: 15px;
    //                 color: #FFFFFF;
    //                 margin-bottom: 59px;
    //             }

    //             .body {
    //                 font-weight: 400;
    //                 font-size: 16px;
    //                 line-height: 22px;
    //                 text-align: center;
    //                 color: #FFFFFF;
    //                 margin-top: 17px;
    //             }
    //         }

    //         .content-navigation-container {
    //             display: flex;
    //             align-items: center;
    //             width: 50%;
    //             justify-content: space-between;
    //             margin: 0 auto;

    //             .radio-button-container {
    //                 min-width: 120px;
    //                 text-align: center;

    //                 .navigate-input {
    //                     padding: 0px;
    //                     margin: 0px 5px;
    //                     width: 10%;
    //                     cursor: pointer;
    //                 }
    //             }

    //             .arrow {
    //                 cursor: pointer;
    //             }

    //         }
    //         }
            
    //     }
    // }
}