.io-table-container {
    width: 100%;
    height: 100%;
    overflow: scroll;

    .io-table {
        width: 100%;
        height: 100%;
        border-collapse: separate;
        border-spacing: 0;

        th,
        td {
            min-width: 105px;
            height: 40px;
            box-sizing: border-box;
            border: 1px solid #4D4D4D;
            padding: 5px 12px;
        }

        .sticky-left {
            position: sticky;
            left: 0;
            z-index: 10;
        }

        .sticky-right {
            position: sticky;
            right: 0;
        }

        .balance {
            min-width: 180px;
        }
    }


    thead {
        position: sticky;
        top: 0px;
        z-index: 1000;
        th {
            background: #333333;
            .content {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .icon {
                    display: flex;
                }
            }
        }

        .border-right {
            border-left: none;
            border-bottom: none;
            border-top: none;
        }

        .border-left {
            border-right: none;
            border-bottom: none;
            border-top: none;
        }
    }

    tbody {
        td {
            background: #1F1F1F;

            .content {
                display: flex;
                justify-content: space-around;
                align-items: center;

                .icon {
                    display: flex;
                }
            }

            .input-container {
                height: 100%;
                width: 100%;
                text-align: center;

                .data-input {
                    width: 100%;
                    height: 100%;
                    border: none;
                    background: none;
                    color: white;
                    padding: 0px;
                    box-sizing: border-box;
                    outline: none;
                    text-align: center;
                }

                .data-input[readonly] {
                    cursor: not-allowed;
                }
            }
        }

        .ti-to {
            border-right: none;
        }

        .parameters {
            border-left: none;
        }
    }
}