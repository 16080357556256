.alarm-history-container {
  height: 94%;
  // width: 100%;
  position: relative;
  // overflow: scroll;
  margin-top: 50px;

  .alarm-history-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;

    .alarm-history-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }
  }

  .alarm-history-data-container {
    // margin-top: 24px;
    // height: 79%;
    height: 96%;
    width: 100%;
  }
}