.notes-container {
  height: 94%;
  overflow: scroll;

  .notes-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: 17px;

    .notes-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }

    .notes-count-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
    }
  }

  .notes-data-container {
    height: 80%;
    overflow: scroll;
    margin-bottom: 10px;
  }

  .input-container {
    width: 100%;
    position: relative;
    margin-top: 20px;
    height: 8%;
    min-height: 50px;
    background: #333333;
    border-radius: 8px;

    .notes-input {
      width: 90%;
      height: 100%;
      max-height: 54px;
      box-sizing: border-box;
      background: #333333;
      border-radius: 8px;
      border: none;
      padding: 16px;
      color: #FFFFFF;
      outline-color: none;
      font-size: 16px;
      font-family: Noto sans;
      overflow: hidden;
      resize: none;
    }

    .notes-input:focus {
      // outline-color: #FFFFFF;
      outline: none;
      border: none;
    }

    .input-logo {
      position: absolute;
      top: 13px;
      right: 20px;
      width: 10%;
      max-width: 60px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.bed-details-notes-container {
  display: flex;
  gap: 24px;
  height: 100%;

  .notes-left-panel {
    width: 25%;
    height: 100%;
    background: #1A1A1A;
    border-radius: 12px;
    padding: 24px 16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .notes-header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      // margin-top: 17px;

      .notes-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #FFFFFF;
      }

      .notes-count-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .notes-data-container {
      height: 80%;
      overflow: scroll;
    }

    .add-note-button-container {
      // margin-top: 24px;
      position: sticky;
      bottom: 0;
      align-self: flex-end;
      width: 100%;

      .add-note-button {
        height: 48px;
        width: 100%;
        background: #6D1DF0;
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .notes-right-panel {
    width: 73%;
    height: 100%;
    background: #1A1A1A;
    border-radius: 12px;
    box-sizing: border-box;

    .add-note-container {
      height: 100%;


      .add-note-header {
        padding: 24px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 15%;
        box-sizing: border-box;

        .add-note-text {
          font-weight: 600;
          font-size: 20px;
          line-height: 27px;
          letter-spacing: -0.02em;

          color: #FFFFFF;
        }

        .add-note-cta-container {
          display: flex;
          gap: 24px;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;

          .add-note-cta {
            border-radius: 6px;
            cursor: pointer;
          }

          .cancel {
            padding: 13px 14px;
            color: #808080;
            border: 1px solid #808080;

          }

          .save {
            padding: 13px 12px;
            color: #FFFFFF;
            background: #6D1DF0;
          }
        }
      }

      .editor-container {
        height: 85%;
      }
    }
  }
}