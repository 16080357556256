.table-clinical-score {
    overflow: scroll;
    display: flex;
    height: 100%;
    width: 100%;

    .heading {
        background: #333333;
        text-align: center;
        position: sticky;
        top: 0;
        height: 48px;
        line-height: 3.4;
    }

    .column {
        flex: auto;
        .not-allowed {
            cursor: not-allowed;
        }
    }

    .parameters {
        position: sticky;
        left: 0px;
        background: #1F1F1F;
        max-width: 180px;
        z-index: 1000;
    }

    .param {
        overflow: scroll;
    }

    .first-column-width{
        width: 180px;
    }
    .cell-width{
            width: 72px;
        }
    
    .clinical-score-cell{
        padding:8px 12px;
        border: 1px solid #333333;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        height: 90px;

        
        
        .cell-param-header{
            display: flex;
            align-items: center;
            gap: 8px;

            .default-container{
                padding: 7px;
                position: absolute;
                width: 100px;
                height: 62px;
                background: #4D4D4D;
                filter: blur(0.4px);
                border-radius: 6px;
                background-color: rgba(0,0,0, 0.4);

            }
            .sofa-info-score-container{
                top: 90px;
            }
            .gcs-info-score-container{
                top: 180px;
            }

            .common-info-content{
                z-index: 1000;
                display: flex;
                flex-direction: column;
                .common-modal{
                    display: flex;
                    gap: 5px;
                    align-items: baseline;
                    img{
                        height: 6px;
                        width: 6px;
                    }
                    span{
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: white;
                        margin: 3px;
                    }
                }
            }

            .cell-label{
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
            }

            .clinical-score-cell-header{
                height: 48px;
            }

            .show-score{
                text-align: center;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                background-color: green;
                border-radius: 4px;
                padding: 4px 6px;
                gap: 10px;
                width: 64px;
                height: 24px;
                margin-left: 20px;
            }
        }

        span{
            margin-top: 10px;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #808080;
        }
        .chart-input {
            width: 100%;
            height: 100%;
            border: none;
            background: none;
            color: white;
            padding: 0px;
            box-sizing: border-box;
            outline: none;
        }

        .chart-input[readonly] {
            cursor: not-allowed;
        }
    }
}
