.prescription-table-container {

    overflow: scroll;

    .prescription-table-header {

        margin-bottom: 24px;
    }

    .prescription-heading-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
    }

    table {
        // border: 2px solid forestgreen;
        width: 100%;
        height: 40px;
        border-collapse: collapse;
        text-align: center;
        overflow: scroll;

        td,
        th {
            border: 1px solid #4D4D4D;
            ;
        }

        tr {
            border-bottom: 0.5px solid grey;
        }
    }

    th {
        border-bottom: 1px solid black;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        background: #333333;
        padding: 16px;
    }

    td {
        text-align: center;
        background: #1f1f1f;
        padding: 12px 16px;
    }

    .start-stop-container {
        font-weight: 600;
        font-size: 8px;
        line-height: 11px;
        display: flex;
        justify-content: space-around;
        gap: 5px;

        .action-button {
            padding: 4px 8px;
            background: #AD0606;
            border-radius: 2px;
            width: min-content;
            cursor: pointer;
        }

        .start-button {
            background: #268D3D;
        }
    }
}