.bedcard-monitor-container {
    width: 425px;
    height: 260px;

    .bedcard-monitor {
        // background: #0D0D0D;
        background: #292929;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 16px 24px 12px 24px;
        cursor: pointer;

        .bed-patient-details {

            .bed-header {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                align-items: baseline;

                .bed-number {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                }

                .high-priority {
                    background: #AD0606;
                }

                .low-priority {
                    background: #3ADAE5;
                }

                .medium-priority {
                    background: #FCC331;
                }

                .alarm-container {
                    padding: 7px 12px 7px 12px;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    border-radius: 4px;
                    // min-width: 175px;
                    min-width: 190px;

                    .top-alarm-container {
                        height: 14px;
                        overflow: hidden;
                    }

                    .alarm-number-container {
                        display: flex;
                        cursor: pointer;

                        .arrow-icon {
                            margin-left: 5px;
                        }
                    }

                    .more-alarm-container {
                        position: absolute;
                        top: 110%;
                        background: #1A1A1A;
                        border: 0.6px solid #333333;
                        border-radius: 4px;
                        width: 100%;
                        padding: 4px;
                        z-index: 1000;
                        left: 0px;
                        box-sizing: border-box;

                        .more-alarm {
                            padding: 5px 12px;
                            background: #333333;
                            margin-bottom: 2px;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            color: #FFFFFF;

                            .alarm-time {
                                float: right;
                            }
                        }

                        .see-all-container {
                            text-align: center;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 16px;
                            padding: 7px 16px;
                            cursor: pointer;
                        }
                    }
                }

                .icon-container {
                    cursor: pointer;
                }


                .no_risk {
                    background: #299842;
                }

                .mild_risk {
                    background: #fcc331;
                }

                .notification-container {
                    grid-column: 15/15;
                    position: relative;
                    width: 35px;

                    .notification-details {
                        width: 100%;
                        position: absolute;
                        width: max-content;
                        right: 0;
                        top: 150%;
                        background: #333333;
                        border-radius: 8px;
                        z-index: 900;
                        padding: 4px;

                        .notification {
                            padding: 7px 10px 7px 16px;
                            display: flex;
                            align-items: center;

                            .notification-name {
                                width: 200px;
                                height: 20px;
                                overflow: scroll;
                            }

                            .ack-button {
                                background: #6D1DF0;
                                border-radius: 4px;
                                padding: 6px 12px;
                                margin-left: 10px;
                                cursor: pointer;
                            }

                            // .ack-button:before {
                            //   content: "";
                            //   display: inline-block;
                            //   position: absolute;
                            //   right: 2.5%;
                            //   top: -10px;
                            //   border-bottom: 10px solid #333333;
                            //   border-left: 10px solid transparent;
                            //   border-right: 10px solid transparent;
                            // }
                        }
                    }

                    .notification-details:before {
                        content: "";
                        display: inline-block;
                        position: absolute;
                        // right: 2.5%;
                        // left: 92%;
                        right: 15px;
                        top: -10px;
                        border-bottom: 10px solid #333333;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                    }
                }

                .favorite-icon {
                    grid-column: 16/16;
                }
            }

            .patient-details {
                display: flex;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #9a9a9a;
                margin-top: 8px;

                .no-patient-detail-container {
                    display: flex;
                    gap: 5px;
                    align-items: center;

                    .add-patient-btn {
                        color: #6D1DF0;
                        text-decoration: underline;
                    }
                }

                .pipe {
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
        }

        .hr-line {
            border: 1px solid #333333;
        }

        .vital-details {
            height: 170px;

            .device-heading {
                display: flex;
                gap: 10px;

                .device-mode {
                    .ventilator-mode {
                        margin-left: 3px;
                    }
                }
            }

            .device-name {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #808080;
            }

            .device-data {
                display: flex;
                gap: 8px;
                width: 372px;
                color: #ffffff;
                text-align: center;
                margin: 12px auto;
                justify-content: space-between;

                .vital-data-detail {
                    box-sizing: border-box;
                    // background: #1F1F1F;
                    background: #3D3D3D;
                    padding: 5px;
                    border-radius: 6px;
                    height: 72px;
                    min-width: 72px;
                }

                .critical {
                    background: #ad0606;
                }

                .vital-name {
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 14px;
                }

                .vital-data {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 27px;

                    .unit {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        // color: #808080;
                        // margin-left: 5px;
                    }
                }
            }


            .select-vital-graph-container {
                display: flex;
                gap: 5px;
                position: relative;
                cursor: pointer;
                width: 50px;

                .selected-vital-name {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    letter-spacing: -0.02em;
                    width: 30px;
                }

                .vital-graph-options {
                    position: absolute;
                    top: 20px;
                    width: 184px;
                    // height: 322px;
                    padding: 10px;
                    background: rgba(52, 52, 52, 0.5);
                    border: 1px solid #404040;
                    backdrop-filter: blur(40px);
                    border-radius: 6px;
                    z-index: 10;

                    .common-vital-option {
                        padding: 10px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 36px;
                        &:hover{
                            background-color: #9F33FF;
                        }
                    }

                    .vital-graph-active {
                        background-color: #6D1DF0;
                        border-radius: 4px;
                    }
                }
            }

            .graph-container {
                height: 80px;
                display: flex;
                gap: 10px;
                align-items: baseline;
                justify-content: space-around;

                .graph-rate-container {
                    align-self: center;

                    .rate-title {
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 33px;
                    }
                }
            }
        }

        //   .patient-cta {
        //     display: grid;
        //     grid-template-columns: 1fr 1fr 1fr 1fr;
        //     font-weight: 600;
        //     font-size: 12px;
        //     line-height: 16px;
        //     text-align: center;

        // .cta-button {
        //   margin-right: 7px;
        //   padding: 10px 7px 10px 7px;
        //   background: #333333;
        //   cursor: pointer;
        // }
        //   }
    }
}

.left-panel-card {
    height: 150px;
    margin: 5px;
    width: 340px;

    .bedcard-monitor {
        padding: 5px;
        border-radius: 6px;

        .bed-patient-details {
            padding: 5px;

            .bed-header {

                .alarm-container {
                    padding: 4px 12px 4px 12px;
                }

                .bed-number {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: -0.02em;
                }
            }

            .patient-details {
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                letter-spacing: -0.02em;
            }
        }

        .vital-details {
            height: 68px;

            .device-data {
                margin-top: 3px;
                gap: 8px;
                width: 318px;

                .vital-data-detail {
                    box-sizing: border-box;
                    height: 64px;
                    border-radius: 3px;
                    padding: 6px;
                    min-width: 48px;
                }
            }
        }
    }
}

.selected-card {
    border: 1px solid #6D1DF0;
    border-radius: 6px;
}