@import 'draft-js/dist/Draft.css';

.rich-text-editor {
    height: 100%;
    background: #1A1A1A !important;
    border-radius: 12px !important;
    border-style: none !important;
    display: flex;
    flex-direction: column-reverse;

    .rte-toolbar {
        display: flex;
        margin: 0;
        padding: 24px;
        border-top: 1px solid #4D4D4D;
        border-bottom: none;
        border-radius: 0 0 12px 12px;
        overflow: scroll;
        box-sizing: border-box;
        height: 15%;
        // background: white;

        button {
            font-weight: 700;
            font-size: 24px;
            line-height: 33px;
            border: none;
            color: #FFFFFF;
            background: #FFFFFF;

            > * {
                color: #FFFFFF
            }

            ;
        }

        .IconButton__isActive___2Ey8p {
            background: #d8d8d8;
        }

        .InputPopover__root___3Hpj9 {
            background: #0d0d0d;
        }

        .Dropdown__root___3ALmx {
            // color: black;

        }
    }

    .rte-editor {
        height: 85%;
        padding: 15px;
        box-sizing: border-box;

        .DraftEditor-root {
            height: 100%;
        }
    }
}

.draft-editor-container,
.DraftEditor-root,
.draft-editor {
    height: 100%;
    background: #1A1A1A;
    border-radius: 12px;
}