.report-container{
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px 10px;

    .patient-detail-info-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 36px;
        border-radius: 12px;
        background: var(--theme-dg-1512, #1F1F1F);
        z-index: 1004;
        width: 25%;
        position: absolute;
        left: 35%;
        top: 6%;
        .report-input-container{
            display: flex;
            flex-direction: column;
            input{
                font-family: 'Noto Sans';
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .input-label{
                padding-bottom: 6px;
                color: #a8a8a8;
            }
            .react-date-picker__wrapper {
                border: none;
                padding-inline-start: 15px;
                background-color: #333;
                height: 42px;
                border-radius: 6px;
            }

            .react-date-picker__inputGroup {
                padding: auto 10px;
            }
            .react-date-picker__inputGroup__input {
                color: white;
                width: 18px;
                
            }
            .report-time-picker{
                .react-time-picker__inputGroup__input {
                    color: white;
                    font-family: 'Noto Sans';
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
            
        }
        
        .pd-input {
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            background: #333;
            border-radius: 8px;
            padding: 12px 16px;
            border: none;
            color: #FFFFFF;
            font-size: 16px;
            outline: none;
        }
        .report-time-picker{
            .react-time-picker__inputGroup__amPm option{
                background-color: #000000;
            }
        }
        .react-time-picker--disabled {
            background-color: #141414;
            border-radius: 8px;
        }

        .react-time-picker__wrapper {
            background-color: #333;
            height: 42px;
            border-radius: 6px;
            border: none;
            padding-left: 15px;
        }
        .info-btn-container{
            display: flex;
            gap: 30px;
            justify-content: center;

            @keyframes spinner {
                to {transform: rotate(360deg);}
            }
            .spinner {
                content: '';
                box-sizing: border-box;
                position: relative;
                top: 50%;
                width: 20px;
                height: 20px;
                margin-top: -10px;
                margin-left: -10px;
                border-radius: 50%;
                border: 1px solid #f6f;
                border-top-color: #0e0;
                border-right-color: #0dd;
                border-bottom-color: #f90;
                animation: spinner .6s linear infinite;
            }
            .disabled-btn{
                display: flex;
                gap: 8px;
                justify-content: space-around;
                background-color:silver;
                color: #292929;
                cursor: progress;
                text-align: center;
                line-height: 3;
                height: 48px;
                padding: 6px 12px;
                border-radius: 6px;
                font-family: Noto Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 2.5;
                min-width: 40%;
            }
            .common-info-container-btn{
                cursor: pointer;
                text-align: center;
                line-height: 3;
                height: 48px;
                padding: 6px 12px;
                border-radius: 6px;
                background: var(--theme-dg-220, #333);
                font-family: Noto Sans;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 2.5;
                min-width: 40%;
            }
            .confirm-info-btn{
                background: var(--primary, #6D1DF0);
                a{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .report-type-header{
        font-family: Public Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.32px;
    }
    .vitals-report-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .form-input {
            .trend-date {
                background: #333;
                height: 42px;
                border-radius: 6px;
            }
            .label{
                margin-bottom: 10px;
            }
            .react-date-picker__wrapper {
                background: #1F1F1F;
                border-radius: 8px;
            }
        }

        .trend-input {
            min-height: 40px;
            height: 42px;
        }
        .start-date-report-generate-container{
            display: grid;
            grid-template-columns: 1fr 0.5fr;
            grid-gap: 30px;
        }
        .report-btn{
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .ecg-report-info{
            font-family: Public Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
        }
        .generate-btn{
            text-align: center;
            line-height: 50px;
            width: 300px;
            height: 54px;
            padding: 6px 12px;
            border-radius: 6px;
            background: var(--primary-purple, #6D1DF0);
            cursor: pointer;
        }
    }
    .ecg-report-container{
        display: flex;
        flex-direction: column;
        gap: 20px;
        .report-btn{
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
        .ecg-report-info{
            font-family: Public Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.32px;
        }
        .generate-btn{
            text-align: center;
            line-height: 50px;
            width: 300px;
            height: 54px;
            padding: 6px 12px;
            border-radius: 6px;
            background: var(--primary-purple, #6D1DF0);
            cursor: pointer;
        }
    }
}