.prescription-container {
    max-height: 90%;
    overflow: scroll;

  .prescription-header-container {
    display: flex;
    justify-content: space-between;

    .prescription-text {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;
    }

    .option-container {
      display: flex;
      gap: 24px;
    }

    .date-container {
      margin-left: auto;
    }

    .prescription-option-container {
      margin-top: 0px;
    }
  }

  .prescription-option-container {
    margin-top: 24px;
    display: flex;
    gap: 24px;
    color: #808080;

    .new-medication-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
    }

    .option-container {
      display: flex;
      gap: 24px;
    }

    .prescription-option {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;
    }

    .selected-option {
      padding-bottom: 10px;
      border-bottom: 2px solid #ffffff;
      color: #FFFFFF;
    }

    .add-drug-container {
      // margin-top: 24px;
      // width: 10%;
      text-align: center;
      color: #FFFFFF;
      margin-left: auto;

      .add-drug-button-container {
        width: 10%;

        .add-drug-button {
          padding: 10px 12px;
          background: #6d1df0;
          border-radius: 4px;
          min-width: 70px;
          cursor: pointer;
        }
      }

      .submit-container {
        grid-column: 3/4;
        display: flex;
        gap: 16px;
        justify-content: flex-end;

        .cancel-button {
          padding: 10px 20px;
          border: 1px solid #666666;
          border-radius: 4px;
          cursor: pointer;
          color: #666666;
        }

        .submit-button {
          padding: 10px 20px;
          color: #FFFFFF;
          background: #6D1DF0;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .add-prescription-container {
    margin-top: 20px;

    .new-medication-text {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .input-label {
      margin-top: 10px;

    }

    .add-prescrition-form {
      margin-top: 16px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 20px;

      .prescription-input {
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        background: #333333;
        border-radius: 8px;
        border-style: none;
        padding: 11px 16px;
        font-size: 16px;
        color: #FFFFFF;
        min-width: 142px;
        margin-top: 10px;
      }
    }

    .input-select-class {
      margin-top: 10px;
      height: 60%;
    }

    .drug-form {

      .drug-name-container,
      .route-container,
      .frequency-container {
        height: 64.5px;
      }

      .remarks-container {
        grid-column: 2/4;
      }
    }

    .infusion-form {
      .additive-container {
        grid-column: 1/2;
      }

      .remove-additive-container{
        margin: auto;
        margin-left: 0;
        position: relative;
        top: 9px
      }

      .add-additive-container {

        display: flex;
        gap: 8px;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        position: relative;
        top: 9px;

        .add-additive-text {
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #6D1DF0;
        }
      }

      .rate-container {
        grid-column: 1/2;
      }

      .infusion-remarks-container {
        grid-column: 1/4;
      }
    }
  }

  .prescription-history-table {
    margin-top: 48px;
  }
}