.gcs-score-container{
   height: 100%;
    width: 100%;
    overflow: scroll;

    .gcs-score-body{
        padding: 16px 36px 0px 16px;
        max-height: calc(100% - 80px);
        max-width: calc(100% - 80px);
        height: 90.3%;
        width: 94.5%;
        float: right;
        box-sizing: border-box;
        margin-top: 80px;

        .gcs-heading-container{
            display: flex;
            gap: 15px;
            align-items: center;

            .heading{
                font-family: 'Noto Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: -0.02em;
            }

            .show-score{
                text-align: center;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                width: 72px;
                height: 30px;
                border-radius: 4px;
            }
        }

        .gcs-rating-container{
            display: flex;
            align-items: center;
            gap: 40px;
            margin-top: 20px;

            .common-rating-cls{
                display: flex;
                gap: 10px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }

        .gcs-mcq-container{
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 40px;

            .common-mcq{
                display: flex;
                flex-direction: column;
                
                .mcq-heading{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 27px;
                    letter-spacing: -0.02em;
                }
                .mcq-score{
                    text-align: end;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 22px;
                    letter-spacing: -0.02em;
                }
                
                .mcq-options-common{
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    gap: 10px 2px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    color: #808080;

                    .radio-opt{
                        display: grid;
                        grid-template-columns:  20px auto;
                        align-items: flex-start;
                        gap: 8px;

                        input{
                            margin-top: 4px;
                            align-self: flex-start;
                            accent-color:#808080 ;
                        }
                        input:checked ~ label{
                            color: white;
                        }
                        input:checked{
                            accent-color: #6D1DF0;
                            // border: 5px solid #AAAAAA;
                            // border-radius: 100%;
                        }
                    }
                }
            }
        }
        .gcs-total-rating-container{
            margin-top: 50px;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            letter-spacing: -0.02em;
            border-top: 1px solid #333333;
            border-bottom: 1px solid #333333;
            padding: 10px;

            .total-rating{
                font-weight: 600;
                font-size: 40px;
                letter-spacing: -0.02em;
            }
        }
        .submit-btn-container{
            margin-top: 20px;
            width: 100px;
            height: 48px;
           
            .submit-btn{
                background: #6D1DF0;
                border-radius: 6px;
                height: 48px;
                width: 100px;
                font-weight: 600;
                font-size: 16px;
                line-height: 44px;
                text-align: center;
                position: absolute;
                bottom: 10px;
                right: 5%;
                cursor: pointer;
            }
        }
    }
}
.red-score{
    background-color: #AD0606;
}
.yellow-score{
    background-color:  #FCC331;
}
.green-score{
    background-color: #268D3D;
}