.patient-modal {
  position: absolute;
  top: 10%;
  height: 80%;
  background: red;
  width: 90%;
  margin-left: 5%;
  // margin: auto;
  background: #1f1f1f;
  border: 1px solid #333333;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  min-height: 700px;
  min-width: 400px;

  .left-menu-bar {
    background: #333333;
    border-radius: 12px;
    width: 20%;
    min-width: 100px;
    // height: 100%;
    box-sizing: border-box;

    .menu-items {
      padding: 62px 8px 0px 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      cursor: pointer;

      .selected {
        background: #6d1df0;
        border-radius: 6px;
      }

      .menu-item {
        padding: min(21px, 10%);
      }
    }
  }

  .right-menu-bar {
    padding: 32px 24px 32px 40px;
    width: 80%;
    height: 100%;
    min-width: 300px;
    box-sizing: border-box;
    // overflow: scroll;

    .modal-right-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 17px;

      .modal-left {
        display: flex;
        gap: 11px;
      }

      .modal-right {
        display: flex;
        gap: 20px;
        align-items: center;

        .modal-cross,
        .modal-expand {
          cursor: pointer;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
 .patient-modal{
  min-height: 560px;
 }
}