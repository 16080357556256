.graph-detail {
  // background-color: red;
  // width: 100%;
  height: 100%;
  .graph-header {
    background-color: #333333;
    display: grid;
    text-align: center;
    align-items: center;
    // grid-template-columns: repeat(12, 1fr);
    grid-template-columns: repeat(15, 1fr);
    padding: 7px 15px;
  }

  .bed-name {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    grid-column: 1/4;
    text-align: start;
  }

  .ews-score {
    padding: 4px 16px;
    background: #299842;
    border-radius: 2px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    grid-column: 4/6;
  }

  .view-details {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    grid-column: 14/16;
    text-align: end;
  }

  .sub-header {
    // background-color: red;
    margin-top: 14px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    padding: 7px 15px;

    .nav-container {
      display: flex;

      .nav-data {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-right: 24px;
        padding-bottom: 8px;
        cursor: pointer;
        color: #808080;
      }

      .active-nav {
        border-bottom: 3px solid #ffffff;
        color: #ffffff;
      }
    }

    .nav-timeline-container {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      .timeline-data {
        background: #396793;
        border-radius: 4px;
        padding: 8px 16px;
        background: #1a1a1a;
        cursor: pointer;
        // margin-right: 8px;
      }

      .active-timeline {
        background: #6d1df0;
      }
    }
  }
}
